//import './style.css';

// Alpine.js

import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
Alpine.plugin(intersect)

window.Alpine = Alpine
Alpine.start()


// CountUp.js

import {
  CountUp
} from 'countup.js'
const options = {
  separator: ' ',
  useEasing: false,
}
let insulation = new CountUp('insulation', 7000, options)
if (!insulation.error) {
  insulation.start()
} else {
  console.error(insulation.error)
}
let paper = new CountUp('paper', 30000, options)
if (!paper.error) {
  paper.start()
} else {
  console.error(paper.error)
}
let co2 = new CountUp('co2', 178000, options)
if (!co2.error) {
  co2.start()
} else {
  console.error(co2.error)
}
let routes = new CountUp('routes', 11200, options)
if (!routes.error) {
  routes.start()
} else {
  console.error(routes.error)
}
let cats = new CountUp('cats', 47000, options)
if (!cats.error) {
  cats.start()
} else {
  console.error(cats.error)
}

// Swiper.js

import {
  Swiper
} from 'swiper/bundle';
//import 'swiper/css';

const swiper = new Swiper(".mySwiper", {
  slidesPerView: 'auto',
  spaceBetween: 100,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  effect: "fade",
  speed: 2000,
  fadeEffect: {
    crossFade: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  rewind: true,
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});